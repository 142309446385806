import React from 'react'
import Formulario from './Formulario'
import LogoyRedes from './LogoyRedes'

export default function Contacto() {
    return (
       
        <div id='contacto' className='contactoStyle pt-2 grid md:grid-cols-4 items-center justify-center'>
            <div className="contactIzq pl-8">
                <h3 className="uppercase text-5xl pt-4">contacto</h3>
                <p className="text-4xl pl-8">¿Tienes alguna sugerencia o algún tema que deba abordar?</p>    
            </div>
            <div className="contactCent md:col-span-1 ml-10 justify-self-center">
                <LogoyRedes />
            </div>
            <div className="contactDer sm:row-span-3 md:col-span-2 md:pl-20 mt-8 justify-self-center">
                <Formulario />
            </div>
        </div>
    )
}
