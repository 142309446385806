import React from 'react'
import ListNoticias from './ListNoticias'

export default function Noticias() {
    return (
        <section className="seccionNoticias pb-8">
            <div className="containerNews flex flex-col md:pl-8 pt-2">
                <h2 className="text-6xl sectionTitle col-span-2 font-black py-2 uppercase self-center">Noticias</h2>
                <ListNoticias />            
            </div>
        </section>
    )
}
