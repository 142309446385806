import React from 'react'
import { getImage, StaticImage  } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import { useStaticQuery, graphql} from 'gatsby'
export default function Hero() {
    const query = graphql` {
            allFile(filter: {relativePath: {eq: "hero.png"}}) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED, 
                            formats: [AUTO, AVIF, WEBP], 
                            width: 1080
                        )
                    }
                }
            }
        }    
    `
    const data = useStaticQuery(query)
    const image = getImage(data.allFile.nodes[0].childImageSharp.gatsbyImageData)
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage className='hero' tag='section' {...bgImage}
        preserveStackingContext>
            <div style={{minHeight: 450}} className='flex items-end pl-8 pb-12 md:items-center md:pl-32 md:pt-32'>
                <StaticImage 
                    src='../../images/nosune.svg'
                    alt='Nos Une'
                    width={400}
                    placeholder='blurred'
                />
            </div>
        </BackgroundImage>


    )
}
