import React from 'react'
import SocialMedia from '../Header/SocialMedia'
import Logotipo from '../Header/Logotipo'
export default function LogoyRedes() {
    return (
        <div className="w-full flex flex-col justify-center items-center bg-white">
            <Logotipo /> 
            <div className="pl-20 pt-2">
                <SocialMedia />
            </div>

                
        

        </div>
    )
}
