import React from 'react'
import { StaticImage  } from "gatsby-plugin-image"

export default function Intro() {

    return (
       
        <div className='introStyle pt-2 grid md:grid-cols-3 justify-center'>
            <div className="introIzq pl-10 pt-6">
                <h3 className="uppercase text-3xl md:text-5xl">
                    segundo <br /><span>informe <br /><span>de </span></span> trabajo  
                </h3>   
            </div>
            <div className="introCent md:col-span-2 ml-10 flex flex-col items-center justify-start">
                <h2 className="text-3xl md:mt-8 md:text-6xl font-black italic py-4">Trabajo en lo que <br /> te importa de verdad</h2>
                <p className='text-lg md:text-2xl'> 
                    <span>
                        <StaticImage 
                            src='../../images/comillas_izq.svg'
                            alt=''
                            width={45}
                            placeholder='blurred'
                        />
                    </span>     
                    Nada en la vida se hace solo, se logran mejores cosas y metas más 
                    grandes haciéndolo en equipo, ser solidarios y responsables garantiza 
                    buenos resultados en lo que hagas y en lo que te comprometas. 
                    Trabajar para el bien de muchos me ha brindado grandes satisfacciones 
                    en mi vida. Estoy convencida plenamente de que las palabras no son 
                    necesarias cuando nuestros ideales, objetivos y convicciones se 
                    demuestran con los hechos y a través del tiempo.
                    <span>
                    <StaticImage 
                        src='../../images/comillas_der.svg'
                        alt=''
                        width={45}
                        placeholder='blurred'
                    />
                </span> 
                </p>    
            </div>
            
        </div>
    )
}
