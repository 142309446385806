import React, {useState, useEffect } from 'react'
import useNoticias from '../Hooks/useNoticias';
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';

export default function ListNoticias() {
    const listadoNoticias = useNoticias();
    const [noticias, guardarNoticias] = useState([]);
    useEffect(() => {
        guardarNoticias(listadoNoticias);
    }, [])
    return (
        <div className="boxNews">
           
                {noticias.map(noticia=>{
                    const image = getImage(noticia.imagen.gatsbyImageData)
                    const bgImageNoticias = convertToBgImage(image)
                    return (
                        <Link to={noticia.slug}  key={noticia.id} className="cursor-pointer">
                        <BackgroundImage className="noticiaStyle cursor-pointer" tag="div" {...bgImageNoticias}>
                            
                            <div className="image-text">
                                <div className="tags-container">
                                {
                                    noticia.stack.map((tag, index)=>{ 
                                        return (
                                                <span 
                                                    key={index} 
                                                    className="tagsNoticias"
                                                >
                                                    {tag}
                                                </span>
                                                )   
                                            }) 
                                }
                                </div>
                                <div className='title-filter'>
                                    <h2 className="image-title">{noticia.titulo}</h2>
                                    <span className="image-date">{noticia.fechaNoticia  }</span>    
                                </div>
                            </div>
                        </BackgroundImage>
                        </Link>        
                       
                                          
                    )
    
                })} 
            
        </div>
    )
}
