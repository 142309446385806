import React, {useState, useEffect} from 'react'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

export default function ListIniciativas({titulo, listado,legislatura}) { 
    
    const [iniciativas, guardarIniciativas] = useState([]);
    useEffect(() => {
        guardarIniciativas(listado);
    }, [])

    const iniciativas_legi = iniciativas.filter(ini => ini.legislatura == legislatura)
    
    return (
        <div>
            <h2 
                className="text-3xl md:text-4xl font-black py-2 self-center"
            >
                {titulo}    
            </h2>
            <ul>
                { iniciativas_legi.map(iniciativa=>{
                    
                        const image = getImage(iniciativa.pin.gatsbyImageData)
                        return (
                            <li key={iniciativa.id} className='flex items-center'>
                                <div className="mx-2">
                                    <GatsbyImage image={image} alt='pin'/>
                                </div>
                                <Link className='iniLink' to={iniciativa.slug}>
                                    <p 
                                        className='text-white text-xl pt-2 pr-2 
                                        border-b-2 border-white h-18 w-60'
                                    > 
                                        {iniciativa.titulo}
                                    </p>
                                </Link>
                            </li>                     
                        )      
                    })
                }    
            </ul> 
        </div>
    )
}
