import React from 'react'

export default function Formulario() {
    return (

                        <div className="w-full max-w-lg">
                            <form 
                                className="px-8
                                pb-8 mb-4"
                                
                            >
                                
                                <div className="mb-2 flex flex-column">
                                    <label 
                                        htmlFor="nombre" 
                                        className="formLabel block mr-4 uppercase pt-2">Nombre:</label>
                                    <input 
                                        type="text" 
                                        id="nombre"

                                        className="formInpt shadow appearance-none border rounded w-full py-1
                                        px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                   
                                </div>
                                <div className="mb-2 flex flex-column">
                                    <label 
                                        htmlFor="apaterno" 
                                        className="formLabel block mr-4 uppercase pt-2">a.paterno:</label>
                                    <input 
                                        type="text" 
                                        id="apaterno"

                                        className="formInpt shadow appearance-none border rounded w-full py-1
                                        px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                   
                                </div>
                                <div className="mb-2 flex flex-column">
                                    <label 
                                        htmlFor="amaterno" 
                                        className="formLabel block mr-4 uppercase pt-2">a.materno:</label>
                                    <input 
                                        type="text" 
                                        id="amaterno"

                                        className="formInpt shadow appearance-none border rounded w-full py-1
                                        px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                   
                                </div>
                                <div className="mb-2 flex flex-column">
                                    <label 
                                        htmlFor="e-mail" 
                                        className="formLabel block mr-4 uppercase pt-2">e-mail:</label>
                                    <input 
                                        type="text" 
                                        id="e-mail"

                                        className="formInpt shadow appearance-none border rounded w-full py-1
                                        px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                   
                                </div>
                                <div className="mb-2 flex flex-column">
                                    <label 
                                        htmlFor="celular" 
                                        className="formLabel block mr-4 uppercase pt-2">celular:</label>
                                    <input 
                                        type="text" 
                                        id="celular"

                                        className="formInpt shadow appearance-none border rounded w-full py-1
                                        px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                                   
                                </div>
                                <div className="mb-2 flex flex-row">
                                    <label 
                                        htmlFor="Comentario ó Propuesta" 
                                        className="formLabel block mr-4 uppercase pt-2">Comentario ó Propuesta:</label>
                                    
                                    <textarea  
                                    className="formInpt shadow appearance-none border rounded w-full py-1
                                        px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    cols="30" rows="5"></textarea>
                                   
                                </div>
                                
                                <div className="flex justify-end">
                                    <input 
                                        type="submit" 
                                        value="Enviar" 
                                        className=" smtBtm cursor-pointer text-lg rounded px-2 py-1 text-white 
                                        uppercase font-bold"    
                                    />
                                </div>
                            </form>
                        </div>

    )
}
