import React from 'react'
import Layout from "../components/Layout"
import Hero from '../components/Inicio/Hero'
import Intro from '../components/Inicio/Intro'
import PropuestasInicio from '../components/Inicio/PropuestasInicio'
import Noticias from '../components/Inicio/Noticias'
import Contacto from '../components/Contacto/Contacto'
import Seo from '../components/Seo'
export default function Home() {
  return (
    <Layout>
      <Seo title="Inicio" />

      <main>
        <Hero />  
        <Intro /> 
        <PropuestasInicio />
        <Noticias />
    
        <Contacto />  
      </main>
    </Layout>
  )
}

