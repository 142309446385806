import {graphql, useStaticQuery } from 'gatsby'

export default function useIniciativas() {
    const resultado = useStaticQuery(graphql`
        query{
            allContentfulIniciativas {
                nodes {
                    id
                    titulo
                    legislatura
                    slug
                    pin {
                        gatsbyImageData(
                            placeholder: BLURRED, 
                            formats: [AUTO,PNG,WEBP]
                        )
                    }
                }
            }
        }
    `);
    return resultado.allContentfulIniciativas.nodes;
}
