import React from 'react'
import { getImage } from "gatsby-plugin-image"
import ListIniciativas from './ListIniciativas'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import { useStaticQuery, graphql} from 'gatsby'
import useIniciativas from '../Hooks/useIniciativas'

export default function PropuestasInicio() {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {relativePath: {eq: "propuestas-inicio-bg.png"}}) {
                nodes {
                  childImageSharp {
                    gatsbyImageData(
                            placeholder: BLURRED, 
                            formats: [AUTO, AVIF, WEBP], 
                            width: 1440
                        )
                    }
                }
            }
        }        
    `)

    const imagePropuestas = getImage(data.allFile.nodes[0].childImageSharp.gatsbyImageData)
    const bgImagePropuestas = convertToBgImage(imagePropuestas)

    const listado = useIniciativas();    

    return (
        <BackgroundImage id='iniciativas' className='lista iniciativas' tag='section' {...bgImagePropuestas}
        preserveStackingContext>
            <div style={{minHeight: 500}} className='propuestasIntro flex flex-col pl-8 pt-2 md:items-center md:pl-28 md:pb-16'>
                <h2 className="text-4xl md:text-6xl col-span-2 font-black py-2 uppercase self-center">iniciativas de ley</h2>
                <div className="zonaBaja grid grid-cols-1 grid-rows-2 md:grid-rows-1 md:grid-cols-4  justify-center pt-5">
                    <div className=" introCent md:col-span-2 ml-10 flex flex-col items-center justify-start hidden md:block">
                        <h3 className="text-4xl italic font-grey-500 py-2">Leyes que mejoran nuestra vida</h3>
                        <p className='text-2xl'>    
                            Gracias a su amplia experiencia como diputada, <br />
                            Alejandra López Noriega conoce muy bien el <br />
                            funcionamiento del Congreso del Estado. <br />
                            <br />
                            Es por ello que cuenta con gran capacidad <br />
                            para generar leyes, que se aprueben y que <br />
                            resuelvan lo que de verdad importa, en temas <br />
                            que mejoran nuestras vidas. <br />
                            <br />  
                            Se ha concentrado en problemas que ha <br />
                            observado durante sus recorridos por las <br />
                            colonias del Distrito X de Hermosillo. <br />
                        </p>    
                    </div>
                    <div className="der md:pl-2 md:col-span-1">
                        
                        <ListIniciativas 
                            titulo={'Legislatura LXIII'}
                            legislatura={63}
                            listado={listado} 
                        /> 
                    </div>
                    <div className="der mb-8 md:mb-2 md:pl-4 md:col-span-1">
                        
                        <ListIniciativas 
                            titulo={'Legislatura LXII'}
                            legislatura={62}
                            listado={listado}
                        /> 
                    </div>


                </div>
            </div>
        </BackgroundImage>    
    )
    
}


