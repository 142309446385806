import {graphql, useStaticQuery } from 'gatsby'

export default function useNoticias() {
    const resultado = useStaticQuery(graphql`
    query {
        allContentfulNoticias(sort: {fields: fechaNoticia, order: DESC}, limit: 5) {
          nodes {
            id
            titulo
            slug
            stack
            fechaNoticia(formatString: "DD-MMMM-YYYY", locale: "es")
            imagen {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO,PNG,WEBP])
            }
          }
        }
      } 
    `);
    return resultado.allContentfulNoticias.nodes;
} 
